import axios from "axios";

export function requestGetTest() {
  return axios.request({
    method: "get",
//    url: "https://statsapi.web.nhl.com/api/v1/schedule?startDate=2022-10-27&endDate=2022-10-30&hydrate=team,linescore,game(content(media(epg)),seriesSummary),metadata,seriesSummary(series)&site=en_nhlNORDIC&teamId=&gameType=&timecode="
//    url: "https://statsapi.web.nhl.com/api/v1/game/2022020111/feed/live"
    url: "https://pv5fhqonot2lvxlzc4h7yzsmje0pruka.lambda-url.us-east-1.on.aws/"
//    url: "http://localhost:6060/posts"
  });
}

export function requestPostTest(action) {
  console.log('export function requestPostTest()', action.payload[0]);
  return axios.request({
    method: "put",
    url: "http://localhost:6060/posts/",
    data: { ...action.payload[0] }
  });
  /*
  */
}
