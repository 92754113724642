import {
    configureStore,
    combineReducers,
    getDefaultMiddleware
  } from "@reduxjs/toolkit";
  import createSagaMiddleware from "redux-saga";
  import { watcherSaga, posterSaga } from "./sagas/rootSaga";
  import testReducer from "./ducks/testSlice";
  
  const sagaMiddleware = createSagaMiddleware();
  
  const reducer = combineReducers({
    test: testReducer
  });
  
  const store = configureStore({
    reducer,
    middleware: [...getDefaultMiddleware({ thunk: false }), sagaMiddleware]
  });
  sagaMiddleware.run(watcherSaga);
  sagaMiddleware.run(posterSaga);
  
  export default store;
  