import React, { useState } from 'react';
import Button from 'react-bootstrap/Button';
import PopModalDialog from './PopModalDialog';

import logo from './logo.svg';
import agileologo from './images/agileo_logotype_s.png';

/*

https://react-hook-form.com/api/useform/

api:
https://nj6hsz9a4b.execute-api.eu-west-1.amazonaws.com/dev

cms.agileo.fi
crm.agileo.fi
erp.agileo.fi

crm.agileo.fi
SugarCRM

notion.so

LinkedIN, GitHub



*/
function getLists() {
  this.setState({ loading: true }, () => {
    fetch("http://localhost:3002/links")
      .then(res => res.json())
      .then(result =>
        this.setState({
          loading: false,
          alldata: result
        })
      )
      .catch(console.log);
  });
}

function LgButton(props) {
  function handleClick() {
    console.log(props.targetUrl);
    window.location = props.targetUrl;
  }
  return <Button className={props.className} onClick={handleClick} >{props.displayStr}</Button>;
}
function DevDisplays(props) {
  <h2>{window.location.pathname}</h2>
}


function AgileoApp() {
  //getLists();
  const [show, setShow] = useState(false);

  const handleClose = () => setShow(false);
  const handleShow = () => setShow(true);

  return (
    <div className="App-Agileo">
      <header className="App-header">
        <img src={agileologo} className="logo" alt="logo" />
      </header>

      <div className='app-container'>
        <h2>apps</h2>
        <div className='linkscontainer appslinks'>
          <LgButton displayStr="Odoo ERP" targetUrl="https://erp.agileo.fi" className="btn-primary btn-large" />
          <LgButton displayStr="OwnCloud" targetUrl="https://cms.agileo.fi" className="btn-primary btn-large" />
          <LgButton displayStr="SugarCRM" targetUrl="https://crm.agileo.fi" className="btn-primary btn-large" />
        </div>
        <h2>links</h2>
        <div className='linkscontainer'>
          <LgButton displayStr="Agileo kotisivu" targetUrl="https://www.agileo.fi" className="btn-secondary btn-large" />
          <LgButton displayStr="Agileo Linkedin" targetUrl="https://www.linkedin.com/company/agileo-oy/" className="btn-secondary btn-large" />
          <LgButton displayStr="Agileo Github" targetUrl="https://github.com/AgileoOy" className="btn-secondary btn-large" />
          <LgButton displayStr="notion.so" targetUrl="https://www.notion.so/" className="btn-secondary btn-large" />
        </div>
      </div>
      <div className='app-footer'>
        <p>
          Powered by Agileo. &copy; {(new Date().getFullYear())}
          <PopModalDialog/>
          <PopModalDialog name="odoo"/>
          <PopModalDialog name="owncloud"/>
          <PopModalDialog name="some other"/>
        </p>
      </div>
    </div>
  );
}


export default AgileoApp;
