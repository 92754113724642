import React, { useEffect,useState,useRef } from "react";
import { useDispatch, useSelector } from "react-redux";
import { getTest, postTest, setTestVals } from "./redux/ducks/testSlice";
import logo from './wutzz logo.svg';
import icon from './wutzzicon.png';
import illust01 from './wutzz_illustration_01.png';
import illust02 from './wutzz_illustration_02.png';
import Container from 'react-bootstrap/Container';
import Row from 'react-bootstrap/Row';
import Col from 'react-bootstrap/Col';
import './App.css';

function App() {
  const [isLoading,setLoading] = useState(false);
  const pathname = window.location.pathname;
//  const pathnameSt = pathname.substring(1,pathname.indexOf('/')+5);
  const [pathnameSt,setPathnameSt] = useState(pathname.substring(1).replace(/(.*?)\/.*/, '$1'));
//  console.log('pathname', pathname);


  const dispatch = useDispatch();

  useEffect(() => {
    dispatch(getTest());
  }, [dispatch]);

  const test = useSelector((state) => state.test);

  while(isLoading){
    if(test.typeOf !== undefined){
      console.log("pööö");
      setLoading(false);
    }
    return <div className="loading">Loading....</div>
  }

  function getBaseChars(num) {
    let bigOne = num; let base = 26; let remainder = 0;
    let baseChars = []; 
    do { 
      let res = bigOne - base * Math.floor(bigOne / base); baseChars.push(res); 
      bigOne = Math.floor(bigOne / base); 
    } while (bigOne);
    return baseChars;
  }
  function getId() { 
    let newdate = new Date().getTime(); 
    let rand = Math.floor(100 * Math.random());
    let datepart = getBaseChars(newdate).map((x) => String.fromCharCode(x + 65)).join("");
    let randpart = getBaseChars(rand).map((x) => String.fromCharCode(x + 65)).join("");
    return ( datepart+ "_" + randpart); 
  }


  function saveValues(vals){
//    console.log('save values '+JSON.stringify(vals));
    dispatch(postTest([test,vals,pathnameSt]));
  }
  function handleFieldChange(event,field) {
//    console.log(event.target.value);
    dispatch(setTestVals([pathnameSt,{...test[pathnameSt],[field]:event.target.value}]));
  }
  function createWutzz() {
//    console.log(event.target.value);
    let newId = getId();
    dispatch(setTestVals([newId,{"title":"New Wutzz","content":"Some content for you newly created wutzz."}]));
    setPathnameSt(newId);
  }
  function navigationBar() {
    return (
      <nav className="navigation top">
      <div className="identity">
        <img className="logo icon" src={icon} onClick={()=>setPathnameSt('')} alt="wutzz" /> 
        { test && test[pathnameSt] && 
          <span>/ {test && test[pathnameSt] && <span>{test[pathnameSt].title}</span>} ({pathnameSt})</span>
        }
      </div>
      <div><a href="#" onClick={(e)=>createWutzz()}>+ new wutzz</a></div>
    </nav>
    );
  }

  return (
    <div className="App" id="top">
      { test && test[pathnameSt] && navigationBar() }
      {test && test[pathnameSt] &&
        <div className="content main" 
              style={
                test && test[pathnameSt] && test[pathnameSt].background?
                { backgroundImage: "url('"+test[pathnameSt].background+"')" }
                :
                test && test[pathnameSt] && test[pathnameSt].imageUrl?
                { backgroundImage: "url('"+test[pathnameSt].imageUrl+"')" }
                :
                { backgroundImage: "none" }
                }
                >
        {test && test[pathnameSt] &&
          <div className="contentdiv">
            <h1>{test[pathnameSt].title}</h1>
            {test[pathnameSt].imageUrl &&<img className="contentimage" src={test[pathnameSt].imageUrl} alt={test[pathnameSt].title}/>}
            <p>{test[pathnameSt].content}</p>
            <ul className="linkbuttons-ul col-md-8">
              <li><a className="button linkbutton" href="#">somelink</a></li>
              <li><a className="button linkbutton" href="#">somelink</a></li>
              <li><a className="button linkbutton" href="#">somelink</a></li>
              <li><a className="button linkbutton" href="#">somelink</a></li>
            </ul>
          </div>
        }
        {test && test[pathnameSt] &&
          <div className="editordiv">
            <div>
              <h2>Edit this WUTZZ</h2>
              <label>Title</label>
              <input type="text" value={ test[pathnameSt].title } onChange={(e)=>handleFieldChange(e,"title")} />
              <label>Image</label>
              <input type="text" value={ test[pathnameSt].imageUrl ? test[pathnameSt].imageUrl : "" } onChange={(e)=>handleFieldChange(e,"imageUrl")} />
              <label>Background</label>
              <input type="text" value={ test[pathnameSt].background ? test[pathnameSt].background : "" } onChange={(e)=>handleFieldChange(e,"background")} />
              <label>Content</label>
              <textarea type="text" value={ test[pathnameSt].content } onChange={(e)=>handleFieldChange(e,"content")}  />
              <label>Links</label>
              <textarea type="text" value={ test[pathnameSt].links ? test[pathnameSt].links : "" } onChange={(e)=>handleFieldChange(e,"links")}  />
              <button onClick={()=>saveValues()} styleClass="button btn-lg">Update</button>
            </div>
            <div className="hide"><pre>{JSON.stringify(test[pathnameSt], null, 2) }</pre></div>
          </div>
        }
        </div>
}
        <div className="content">
        <div className="">
        <div className="imgcontainer">
          <img className="logo" src={logo} onClick={()=>setPathnameSt('')} alt="logo" />
        </div>
        <h1 className="hide">wutzz.com</h1>
        </div>
        </div>
        { test && !test[pathnameSt] && navigationBar() }
        <div className="heroimage"></div>
        <div className="content">
        <div className="contentdiv">          
        <Container>
          <Row>
          <Col className="relative d-none d-md-block"><img className="illust01" src={illust01} alt="illust01" /></Col>
            <Col md={8}>
              <h2>WUT IS IT?</h2>
              <p>Quickly and easily create and share context on any subject. Compare others’ views on the same subjects. See what takes are popular. Have your say.</p>
            </Col>
          </Row>
          <Row>
            <Col md={8}>
              <h2>SHARE ANYWHERE</h2>
              <p>Wutzz are convenient to share on any platform, just create your wutzz and copy and paste the shareable link; wutzz.com/my-context</p>
            </Col>
            <Col className="relative"><img className="illust02" src={illust02} alt="illust02" /></Col>
          </Row>
        </Container>
        </div>
        </div>
        <div className="footer">
        <div className="contentdiv">
        <h3>Your Wutzz's</h3>
        {test && Object.keys(test).map((keyName, i) => (
          <div key={i}>
            <h5>{test[keyName].title} : <a href="#top" onClick={()=>setPathnameSt(keyName)} >clicky</a></h5>
          </div>
        ))}
        </div>
        </div>

        {test.dates && test.dates.map((date) => (
        <div><h4>{date.date}</h4>
          {date.games.map((game) => (
            <div>
              {game.gamePk} : 
              {game.teams.away.team.name} &nbsp;
              <b>{game.teams.away.score}</b> - <b>{game.teams.home.score}</b> 
              &nbsp; {game.teams.home.team.name}
            </div>
          ))}
        </div>
      ))}

    </div>
  );
}

export default App;
