import React, { useState, useEffect } from 'react';
import Button from 'react-bootstrap/Button';
import Modal from 'react-bootstrap/Modal';

import odoologo from './images/odoologo.png';
import odoobg from './images/odoobg.png';
import owncloudlogo from './images/odoologo.png';
import owncloudbg from './images/odoobg.png';

const PopModalDialog = (props) => {
    const [show, setShow] = useState(false);

    const handleClose = () => setShow(false);
    const handleShow = () => setShow(true);

    const [voted, setVoted] = useState(false);
    const [votes, setVotes] = useState(Math.round(Math.random()*16));

    const [nname, setNname] = useState('odoo');

    let images = {
        'odoo':[odoologo,odoobg],
        'owncloud':[owncloudlogo,owncloudbg]
    };
   
    function VoteButton(props) {
        function handleVoteClick() {
            setVoted(true);
            setVotes(votes+1);
        }
        return (
            <div>
                {!voted ? <Button onClick={handleVoteClick} variant="secondary">Äänestä sovellusta</Button> : <p>{votes} käyttäjä(ä) on äänestänyt.</p>}
            </div>
        )
    }
/*
    useEffect(() => {
    });
*/
    return (
        <React.Fragment>
            <span class="pog hoveropacity75" onClick={handleShow}>?</span>
            <Modal show={show} onHide={handleClose}
                aria-labelledby="contained-modal-title-vcenter"
                centered
                backdrop={true}
                dialogClassName="width1000"
            >
                <div class="grid">
                    <div class="row">
                        <div class="col-md-6 col-sm-12">
                            <img src={images[nname][1]} alt="odoo-bg" class="popupbg"/>
                        </div>
                        <div class="col-md-6 col-sm-12 p-5 popupfg">
                            <img src={images[nname][0]} alt="odoo-logo" />
                            <h2>{props.name} Toiminnanohjaus-järjestelmä</h2>
                            <p>ERP-projekteja, digikonsultointia ja verkkokauppoja pienille ja keskisuurille yrityksille.</p>
                            <VoteButton/>
                            <p><a href="#">Klikkaa tästä lisätietoja ja tilaa.</a></p>
                        </div>
                    </div>
                </div>
            </Modal>
        </React.Fragment>
    );
}


export default PopModalDialog;