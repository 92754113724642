import React from 'react';
import ReactDOM from 'react-dom/client';
import './index.css';
import ThemeSelector from './themes/ThemeSelector';
import App from './App';
import AgileoApp from './themes/agileo/agileo-app.js';
import reportWebVitals from './reportWebVitals';
import { Provider } from "react-redux";
import store from "./redux/configureStore";

const root = ReactDOM.createRoot(document.getElementById('root'));
const path = window.location.pathname;
const env = 'agileo';

root.render(
  <React.StrictMode>
    <Provider store={store}>
      <ThemeSelector env={env}>
        { env && env=='agileo' && <AgileoApp /> }
        { !env || (env && env!='agileo') && <App /> }
      </ThemeSelector>
    </Provider>
  </React.StrictMode>
);

// If you want to start measuring performance in your app, pass a function
// to log results (for example: reportWebVitals(console.log))
// or send to an analytics endpoint. Learn more: https://bit.ly/CRA-vitals
reportWebVitals();


/*

import React from "react";
import ReactDOM from "react-dom";
import App from "./App";
import { Provider } from "react-redux";
import store from "./redux/configureStore";

const rootElement = document.getElementById("root");
ReactDOM.render(
  <React.StrictMode>
    <Provider store={store}>
      <App />
    </Provider>
  </React.StrictMode>,
  rootElement
);
*/