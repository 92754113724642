import { createSlice } from "@reduxjs/toolkit";

const testSlice = createSlice({
  name: "test",
  initialState: {},
  reducers: {
    getTest() {},
    postTest(state, action) {
      console.log('slice post ');
      const testData = action.payload;
      return { ...state, ...testData };
    },
    setTest(state, action) {
//      console.log('slice set ');
      const testData = action.payload;
      return { ...state, ...testData };
    },
    setTestVals(state, action) {
//      console.log('slice set vals',action.payload);
      const testData = action.payload[1];
      let pId = action.payload[0];
      let setPayload = {...state};
      setPayload[pId] = testData;
//      console.log('slice set valsstate ',setPayload);
      return { ...setPayload };
    }
  }
});

export const { getTest, postTest, setTest, setTestVals } = testSlice.actions;

export default testSlice.reducer;
