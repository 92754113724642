import React from 'react';
const env = 'agileo';
/**
 * The theme components only imports it's theme CSS-file. These components are lazy
 * loaded, to enable "code splitting" (in order to avoid the themes being bundled together)
 */
const Theme_Agileo = React.lazy(() => import('./agileo/Theme_Agileo.js'));

const ThemeSelector = (props) => (
  <>
    {/* Conditionally render theme, based on the current client context */}
    <React.Suspense fallback={() => null}>
      {props.env && props.env=='agileo' && <Theme_Agileo />}
    </React.Suspense>
    {/* Render children immediately! */}
    {props.children}
  </>
);

export default ThemeSelector;
