import { takeLatest } from "redux-saga/effects";
import { handleGetTest, handlePostTest } from "./handlers/test";
import { getTest, postTest } from "../ducks/testSlice";

export function* watcherSaga() {
  yield takeLatest(getTest.type, handleGetTest);
  /*
  yield all([
    fork(takeLatest(getTest.type, handleGetTest)),
    fork(handlePostTest)
  ])   
  */
}
export function* posterSaga() {
  yield takeLatest(postTest.type, handlePostTest);
}
