import { call, put } from "redux-saga/effects";
import { setTest, postTest } from "../../ducks/testSlice";
import { requestGetTest, requestPostTest } from "../requests/test";

export function* handleGetTest(action) {
  try {
    const response = yield call(requestGetTest);
    console.log(response);
    const { data } = response;
    yield put(setTest({ ...data }));
  } catch (error) {
    console.log(error);
  }
}
export function* handlePostTest(postdata) {
  console.log('handlePostTest in handlers');      
  try {
    const payload = postdata.payload;
    let pId = payload[2];
    let postPayload = {...payload[0]};
    postPayload[pId] = payload[1];
    console.log("postdata:",postdata);
    console.log("postPayload:",postPayload);
    const response = yield call(requestPostTest(postdata));    
    console.log(response);
    const { data } = response;
    yield put(setTest({ ...data }));
  } catch (error) {
    console.log(error);
  }
}
/*
export function* registerUserHandler({email, password}) {
  try {
    const user = yield call(apiRequests.registerUser(email, password));
    yield put({ type: 'REG_USER', user })
  } catch(e) {
    console.log(e)
  }
} 

export function* registerNewWatcher() {
  yield takeLatest('REGISTER_USER', registerUserHandler);
}
*/